<script>
import Layout from "../../layouts/main";
import PageHeader from '@/components/Page-header';
import appConfig from "../../../../app.config";

/**
 * Feather Icons Component
 */
export default {
   page: {
    title: "Feather Icons",
    meta: [{ name: "description", content: appConfig.description }],
  },
    components: {
    Layout,
    PageHeader,
  },
    data() {
        return {
            title: 'Feather Icons',
            items: [{
                    text: 'Finex',
                    href: '/',
                },
                {
                    text: 'Icons',
                    href: '/',
                },
                {
                    text: 'Feather',
                    active: true,
                },
            ],
        }
    }
}
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row icons-list-demo">
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-alert-octagon"></i> fe-alert-octagon
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-alert-circle"></i> fe-alert-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-activity"></i> fe-activity
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-alert-triangle"></i> fe-alert-triangle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-align-center"></i> fe-align-center
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-airplay"></i> fe-airplay
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-align-justify"></i> fe-align-justify
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-align-left"></i> fe-align-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-align-right"></i> fe-align-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-down-left"></i> fe-arrow-down-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-down-right"></i> fe-arrow-down-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-anchor"></i> fe-anchor
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-aperture"></i> fe-aperture
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-left"></i> fe-arrow-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-right"></i> fe-arrow-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-down"></i> fe-arrow-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-up-left"></i> fe-arrow-up-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-up-right"></i> fe-arrow-up-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-up"></i> fe-arrow-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-award"></i> fe-award
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bar-chart"></i> fe-bar-chart
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-at-sign"></i> fe-at-sign
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bar-chart-2"></i> fe-bar-chart-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-battery-charging"></i> fe-battery-charging
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bell-off"></i> fe-bell-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-battery"></i> fe-battery
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bluetooth"></i> fe-bluetooth
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bell"></i> fe-bell
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-book"></i> fe-book
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-briefcase"></i> fe-briefcase
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-camera-off"></i> fe-camera-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-calendar"></i> fe-calendar
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bookmark"></i> fe-bookmark
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-box"></i> fe-box
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-camera"></i> fe-camera
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-check-circle"></i> fe-check-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-check"></i> fe-check
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-check-square"></i> fe-check-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cast"></i> fe-cast
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevron-down"></i> fe-chevron-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevron-left"></i> fe-chevron-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevron-right"></i> fe-chevron-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevron-up"></i> fe-chevron-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevrons-down"></i> fe-chevrons-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevrons-right"></i> fe-chevrons-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevrons-up"></i> fe-chevrons-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chevrons-left"></i> fe-chevrons-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-circle"></i> fe-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-clipboard"></i> fe-clipboard
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-chrome"></i> fe-chrome
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-clock"></i> fe-clock
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud-lightning"></i> fe-cloud-lightning
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud-drizzle"></i> fe-cloud-drizzle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud-rain"></i> fe-cloud-rain
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud-off"></i> fe-cloud-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-codepen"></i> fe-codepen
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud-snow"></i> fe-cloud-snow
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-compass"></i> fe-compass
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-copy"></i> fe-copy
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-down-right"></i> fe-corner-down-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-down-left"></i> fe-corner-down-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-left-down"></i> fe-corner-left-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-left-up"></i> fe-corner-left-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-up-left"></i> fe-corner-up-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-up-right"></i> fe-corner-up-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-right-down"></i> fe-corner-right-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-corner-right-up"></i> fe-corner-right-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cpu"></i> fe-cpu
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-credit-card"></i> fe-credit-card
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-crosshair"></i> fe-crosshair
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-disc"></i> fe-disc
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-delete"></i> fe-delete
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-download-cloud"></i> fe-download-cloud
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-download"></i> fe-download
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-droplet"></i> fe-droplet
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-edit-2"></i> fe-edit-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-edit"></i> fe-edit
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-edit-1"></i> fe-edit-1
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-external-link"></i> fe-external-link
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-eye"></i> fe-eye
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-feather"></i> fe-feather
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-facebook"></i> fe-facebook
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-file-minus"></i> fe-file-minus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-eye-off"></i> fe-eye-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-fast-forward"></i> fe-fast-forward
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-file-text"></i> fe-file-text
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-film"></i> fe-film
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-file"></i> fe-file
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-file-plus"></i> fe-file-plus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-folder"></i> fe-folder
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-filter"></i> fe-filter
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-flag"></i> fe-flag
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-globe"></i> fe-globe
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-grid"></i> fe-grid
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-heart"></i> fe-heart
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-home"></i> fe-home
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-github"></i> fe-github
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-image"></i> fe-image
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-inbox"></i> fe-inbox
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-layers"></i> fe-layers
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-info"></i> fe-info
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-instagram"></i> fe-instagram
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-layout"></i> fe-layout
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-link-2"></i> fe-link-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-life-buoy"></i> fe-life-buoy
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-link"></i> fe-link
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-log-in"></i> fe-log-in
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-list"></i> fe-list
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-lock"></i> fe-lock
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-log-out"></i> fe-log-out
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-loader"></i> fe-loader
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-mail"></i> fe-mail
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-maximize-2"></i> fe-maximize-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-map"></i> fe-map
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-maximize"></i> fe-maximize
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-map-pin"></i> fe-map-pin
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-menu"></i> fe-menu
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-message-circle"></i> fe-message-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-message-square"></i> fe-message-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-minimize-2"></i> fe-minimize-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-minimize"></i> fe-minimize
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-mic-off"></i> fe-mic-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-minus-circle"></i> fe-minus-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-mic"></i> fe-mic
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-minus-square"></i> fe-minus-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-minus"></i> fe-minus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-moon"></i> fe-moon
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-monitor"></i> fe-monitor
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-more-vertical"></i> fe-more-vertical
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-more-horizontal"></i> fe-more-horizontal
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-move"></i> fe-move
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-music"></i> fe-music
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-navigation-2"></i> fe-navigation-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-navigation"></i> fe-navigation
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-octagon"></i> fe-octagon
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-package"></i> fe-package
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-pause-circle"></i> fe-pause-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-pause"></i> fe-pause
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-percent"></i> fe-percent
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-call"></i> fe-phone-call
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-forwarded"></i> fe-phone-forwarded
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-missed"></i> fe-phone-miss
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-off"></i> fe-phone-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-incoming"></i> fe-phone-incoming
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone"></i> fe-phone
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-phone-outgoing"></i> fe-phone-outgoing
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-pie-chart"></i> fe-pie-chart
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-play-circle"></i> fe-play-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-play"></i> fe-play
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-plus-square"></i> fe-plus-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-plus-circle"></i> fe-plus-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-plus"></i> fe-plus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-pocket"></i> fe-pocket
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-printer"></i> fe-printer
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-power"></i> fe-power
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-radio"></i> fe-radio
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-repeat"></i> fe-repeat
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-refresh-ccw"></i> fe-refresh-ccw
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-rewind"></i> fe-rewind
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-rotate-ccw"></i> fe-rotate-ccw
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-refresh-cw"></i> fe-refresh-cw
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-rotate-cw"></i> fe-rotate-cw
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-save"></i> fe-save
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-search"></i> fe-search
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-server"></i> fe-server
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-scissors"></i> fe-scissors
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-share-2"></i> fe-share-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-share"></i> fe-share
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-shield"></i> fe-shield
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-settings"></i> fe-settings
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-skip-back"></i> fe-skip-back
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-shuffle"></i> fe-shuffle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-sidebar"></i> fe-sidebar
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-skip-forward"></i> fe-skip-forward
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-slack"></i> fe-slack
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-slash"></i> fe-slash
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-smartphone"></i> fe-smartphone
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-square"></i> fe-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-speaker"></i> fe-speaker
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-star"></i> fe-star
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-stop-circle"></i> fe-stop-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-sun"></i> fe-sun
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-sunrise"></i> fe-sunrise
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-tablet"></i> fe-tablet
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-tag"></i> fe-tag
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-sunset"></i> fe-sunset
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-target"></i> fe-target
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-thermometer"></i> fe-thermometer
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-thumbs-up"></i> fe-thumbs-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-thumbs-down"></i> fe-thumbs-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-toggle-left"></i> fe-toggle-left
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-toggle-right"></i> fe-toggle-right
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-trash-2"></i> fe-trash-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-trash"></i> fe-trash
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-trending-up"></i> fe-trending-up
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-trending-down"></i> fe-trending-down
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-triangle"></i> fe-triangle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-type"></i> fe-type
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-twitter"></i> fe-twitter
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-upload"></i> fe-upload
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-umbrella"></i> fe-umbrella
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-upload-cloud"></i> fe-upload-cloud
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-unlock"></i> fe-unlock
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-user-check"></i> fe-user-check
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-user-minus"></i> fe-user-minus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-user-plus"></i> fe-user-plus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-user-x"></i> fe-user-x
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-user"></i> fe-user
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-users"></i> fe-users
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-video-off"></i> fe-video-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-video"></i> fe-video
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-voicemail"></i> fe-voicemail
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-volume-x"></i> fe-volume-x
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-volume-1"></i> fe-volume-1
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-volume-2"></i> fe-volume-2
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-volume"></i> fe-volume
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-watch"></i> fe-watch
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-wifi"></i> fe-wifi
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-x-square"></i> fe-x-square
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-wind"></i> fe-wind
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-x"></i> fe-x
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-x-circle"></i> fe-x-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-zap"></i> fe-zap
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-zoom-in"></i> fe-zoom-in
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-zoom-out"></i> fe-zoom-out
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-command"></i> fe-command
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-cloud"></i> fe-cloud
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-hash"></i> fe-hash
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-headphones"></i> fe-headphones
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-underline"></i> fe-underline
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-italic"></i> fe-italic
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bold"></i> fe-bold
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-crop"></i> fe-crop
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-help-circle"></i> fe-help-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-paperclip"></i> fe-paperclip
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-shopping-cart"></i> fe-shopping-cart
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-tv"></i> fe-tv
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-wifi-off"></i> fe-wifi-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-gitlab"></i> fe-gitlab
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-sliders"></i> fe-sliders
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-star-on"></i> fe-star-on
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-heart-on"></i> fe-heart-on
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-archive"></i> fe-archive
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-down-circle"></i> fe-arrow-down-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-up-circle"></i> fe-arrow-up-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-left-circle"></i> fe-arrow-left-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-arrow-right-circle"></i> fe-arrow-right-circle
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bar-chart-line-"></i> fe-bar-chart-line-
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-bar-chart-line"></i> fe-bar-chart-line
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-book-open"></i> fe-book-open
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-code"></i> fe-code
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-database"></i> fe-database
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-dollar-sign"></i> fe-dollar-sign
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-folder-plus"></i> fe-folder-plus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-gift"></i> fe-gift
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-folder-minus"></i> fe-folder-minus
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-git-commit"></i> fe-git-commit
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-git-branch"></i> fe-git-branch
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-git-pull-request"></i> fe-git-pull-request
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-git-merge"></i> fe-git-merge
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-linkedin"></i> fe-linkedin
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-hard-drive"></i> fe-hard-drive
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-more-vertical-"></i> fe-more-vertical-
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-more-horizontal-"></i> fe-more-horizontal-
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-rss"></i> fe-rss
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-send"></i> fe-send
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-shield-off"></i> fe-shield-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-shopping-bag"></i> fe-shopping-bag
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-terminal"></i> fe-terminal
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-truck"></i> fe-truck
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-zap-off"></i> fe-zap-off
                        </div>
                        <div class="col-xl-3 col-lg-4 col-sm-6">
                            <i class="fe-youtube"></i> fe-youtube
                        </div>

                    </div>
                    <!-- end row -->
                </div>
            </div> <!-- end card -->
        </div> <!-- end col-->
    </div>
    <!-- end row -->
</Layout>
</template>
